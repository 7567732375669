import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CgProfile } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { useNavigate } from 'react-router';

function Header() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    function handleLogout() {
        localStorage.removeItem('user');
        navigate('/login')
    }
    return (
        <Navbar className="justify-content-end bg-body-tertiary header-wrapper">
            <Nav
                className="d-flex align-items-center my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                <CgProfile size={30} />
                <NavDropdown title={user.name} id="navbarScrollingDropdown" className='dropdown'>
                    <NavDropdown.Item onClick={handleLogout}><MdLogout size={20} /> Logout</NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar>
    );
}

export default Header;