import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import NavBar from './components/NavBar';
import Chat from './pages/Chat';
import WebPage from './pages/WebPage';
import BrandVoice from './pages/BrandVoice';
import AISettings from './pages/AISettings';
import PrivateRoute from './components/PrivateRoute';
import Loader from './components/Spinner/Loader';
import BlogPage from './pages/BlogPage';

function App() {
    return (
        <>
            <Loader />
            <Routes>
                <Route path='/' element={<PrivateRoute>
                    <NavBar />
                </PrivateRoute>}>
                    <Route index element={<Chat />} />
                    <Route path='/brandVoice' element={<BrandVoice />} />
                    <Route path='/webPage' element={<WebPage />} />
                    <Route path='/blogPost' element={<BlogPage />} />
                    <Route path='/settings' element={<AISettings />} />
                </Route>
                <Route path='/login' element={<Login />} />
                {/* <Route path='/signup' element={<SignUp />} /> */}
            </Routes>
        </>
    );
}

export default App;
