import React, { useState } from 'react';
import { BiHide } from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import './../scss/Template.scss';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';
import axios from '../util/api';
import { toast } from 'react-hot-toast';

function LoginForm() {
    const [formData, setFormData] = useState({
        email_UserName: '',
        Password: '',
        remember_Me: false
    });
    const [formErrors, setFormErrors] = useState({ emailError: '', passwordError: '' });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateLoginForm = () => {
        var errors = { emailError: '', passwordError: '' };
        let { email_UserName, Password } = formData;
        var isError = false;
        if (!email_UserName.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.emailError = 'Enter Valid email'
            isError = true;
        }

        if (!Password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
            errors.passwordError = 'Enter valid password'
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    function handleChange(e) {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value
            })
        })
    }

    function handleType() {
        setShowPassword(!showPassword)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                email_or_name: formData.email_UserName,
                password: formData.Password
            }
            dispatch(setLoading(true));
            axios.post('/login', data).then((res) => {
                dispatch(setLoading(false));
                if(res.success){
                    localStorage.setItem('user', JSON.stringify(res.user));
                    navigate('/');
                    toast.success(res.message)
                }
            }).catch((error) => {
                if (error.response.status === 400 || error.response.status === 401) {
                    toast.error(error.response.data.error);
                }
                dispatch(setLoading(false));
            })
            
        }
    }
    return (
        <>
            <form className='login-form' onSubmit={handleSubmit}>
                <div className='email_UserName_section'>
                    <input type='email' name='email_UserName' placeholder='Email or Username' onChange={handleChange} value={formData.email_UserName} autoComplete='off'></input>
                </div>
                <span className='form-error'>{formErrors.emailError}</span>
                <div className='password_section'>
                    <input type={showPassword ? 'text' : 'password'} name='Password' placeholder='Password' onChange={handleChange} value={formData.Password}></input>
                    {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow onClick={handleType} />)}
                </div>
                <span className='form-error'>{formErrors.passwordError}</span>
                <div className='remember_me_section'>
                    <input type='checkbox' name='remember_Me' id='rememberMe' onChange={handleChange} checked={formData.remember_Me}></input>
                    <label htmlFor="rememberMe">Remember me?</label>
                </div>
                <button className='login_btn'>Log In</button>
                {/* <p className='new_user_signup' onClick={() => navigate('/signup')}>Create an Account? Sign Up</p> */}
            </form>
        </>
    )
}

export default LoginForm