import React, { useState } from 'react';
import { BiHide } from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import './../scss/Template.scss';
import { useNavigate } from 'react-router';
import axios from '../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';
import { toast } from 'react-hot-toast';

function LoginForm() {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        Name: '',
        email: '',
        Password: '',
        Confirm_Password: '',
    });
    const [formErrors, setFormErrors] = useState({ name: '', email: '', passwordError: '', confirmPasswordError: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            })
        })
    }

    const validateSignupForm = () => {
        var errors = { name: '', email: '', passwordError: '', confirmPasswordError: '' };
        let { Name, email, Password, Confirm_Password } = formData;
        var isError = false;

        if (Name.length < 3) {
            errors.name = 'Enter valid name'
            isError = true;
        }

        if (!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.email = 'Enter Valid email'
            isError = true;
        }

        if (Password.length < 8) {
            errors.passwordError = 'Password length should be more than 8 character'
            isError = true;
        } else if (!/^[A-Z]/.test(Password)) {
            errors.passwordError = 'First Character should be Uppercase'
            isError = true;
        } else if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(Password)) {
            errors.passwordError = 'Password should have atleast 1 special character';
            isError = true;
        }

        if (Password !== Confirm_Password) {
            errors.confirmPasswordError = 'Password doesn\'t match'
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    function handleType() {
        setShowPassword(!showPassword);
    }

    function handleConfirmPasswordType() {
        setShowConfirmPassword(!showConfirmPassword);
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!validateSignupForm()) {
            const data = {
                name: formData.Name,
                email: formData.email,
                password: formData.Password,
                confirm_password: formData.Confirm_Password
            }
            dispatch(setLoading(true));
            axios.post('/signup', data).then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    toast.success('Account Successfully created');
                    navigate('/login')
                }
            }).catch(error => {
                dispatch(setLoading(false));
                if (error.response.status === 400) {
                    toast.error(error.response.data.error);
                }
            })
        }
    }
    return (
        <>
            <form className='signup-form' onSubmit={handleSubmit}>
                <div className='name_section'>
                    <input type='text' name='Name' placeholder='Name' onChange={handleChange} value={formData.Name} autoComplete='off'></input>
                </div>
                <span className='form-error'>{formErrors.name}</span>
                <div className='email_UserName_section'>
                    <input type='email' name='email' placeholder='Email' onChange={handleChange} value={formData.email_UserName} autoComplete='off'></input>
                </div>
                <span className='form-error'>{formErrors.email}</span>
                <div className='password_section'>
                    <input type={showPassword ? 'text' : 'password'} name='Password' placeholder='Password' onChange={handleChange} value={formData.Password}></input>
                    {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow onClick={handleType} />)}
                </div>
                <span className='form-error'>{formErrors.passwordError}</span>
                <div className='confirm_password_section'>
                    <input type={showConfirmPassword ? 'text' : 'password'} name='Confirm_Password' placeholder='Confirm Password' onChange={handleChange} value={formData.Confirm_Password}></input>
                    {!showConfirmPassword ? (<BiHide className='hide_show' onClick={handleConfirmPasswordType} />) : (<BiShow onClick={handleConfirmPasswordType} />)}
                </div>
                <span className='form-error'>{formErrors.confirmPasswordError}</span>
                <button type='submit' className='signup_btn'>Sign Up</button>
                <p className='back_to_login' onClick={() => navigate('/login')}>Already have account? Log In</p>
            </form>
        </>
    )
}

export default LoginForm