import React, { useState, useRef, useEffect } from 'react';
import './../scss/Pages.scss';
import { CiGlobe } from "react-icons/ci";
import { IoChatbubblesOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import axios from '../util/api';
import { backend_url } from '../util/config';
import uniqid from 'uniqid';

function Chat() {
    const [conversations, setConversations] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [displayedResponse, setDisplayedResponse] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const ref = useRef(null);
    const user = JSON.parse(localStorage.getItem('user'));

    const handleSearch = async (e) => {
        e.preventDefault();
        if (userInput.trim()) {
            let chatid = localStorage.getItem('chatid');
            const requestData = {
                search_query: userInput,
                email: user.email,
                chatid: chatid
            }
            await axios.post('/search_google', requestData).then((res) => {
                if (res.search_results) {
                    setConversations((prevConv) => [...prevConv, { request: userInput, response: res.search_results }]);
                    setUserInput('');
                }

            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const handleChatHistoryValue = (chatId) => {
        const data = new FormData();
        data.append('chatid', chatId);

        let config = {
            method: 'post',
            url: `${backend_url}/fetchchathistory`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data
        }

        axios.request(config).then((res) => {
            if (res.result) {
                const newArry = res.result.map((ele)=>{
                    return { request: ele[1], response: ele[2].split(',') }
                })
                setConversations(newArry);
                window.localStorage.setItem('chatid', chatId);
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleNewChat = () => {
        setConversations([])
        const randomId = uniqid();
        window.localStorage.setItem('chatid', randomId);
    }

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
        if (conversations.length) {
            setDisplayedResponse([]);
            const responseString = conversations[conversations.length - 1].response;
            let index = 0;
            setTimeout(() => {
                const responseInterval = setInterval(() => {
                    if (index < responseString.length) {
                        setDisplayedResponse(prev => [...prev, responseString[index++]])
                    } else {
                        clearInterval(responseInterval);
                    }
                }, 20);
                return () => clearInterval(responseInterval);
            }, 1000);
        }

        const fetchhistory = async () => {
            const data = new FormData();
            data.append('email', user.email)

            let config = {
                method: 'post',
                url: `${backend_url}/fetchhistory`,
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                data: data
            }

            await axios.request(config).then((res) => {
                if (res.result) {
                    let obj = {}
                    let newArray = [];
                    res.result.forEach(element => {
                        if (!obj[element[4]]) {
                            newArray.push(element);
                        }
                        obj[element[4]] = 1;
                    });
                    setChatHistory(newArray.reverse())
                }
            }).catch((error) => {
                console.log(error);
            })
        }
        fetchhistory();
    }, [conversations]);

    useEffect(() => {
        const randomId = uniqid();
        window.localStorage.setItem('chatid', randomId);
    }, [])


    return (
        <div className='chat-wrapper'>
            <div className='chat-history'>
                <div className='chat-history-heading' onClick={handleNewChat}>
                    <div>New Chat</div>
                    <FaRegEdit />
                </div>
                <div className='chat-history-content'>
                    {chatHistory.map((val, index) => {
                        return <div key={index} className={`chat-history-value ${activeIndex === index ? 'active-link' : ''}`} onClick={() => {setActiveIndex(index); handleChatHistoryValue(val[4])}}>{val[1].length > 20 ? `${val[1].substring(0, 20)}...` : `${val[1]}`}</div>
                    })}
                </div>
            </div>
            <div className='chat'>
                <div className='chat-heading'><IoChatbubblesOutline size={30} /> Chat</div>
                <div className='chat-content'>
                    {!conversations.length ? (
                        <div className='how_can_i_help'>
                            <CiGlobe size={40} />
                            <p>How can I help you today?</p>

                        </div>
                    ) : (
                        <div className='coversation' ref={ref}>
                            {conversations?.map((chat, index) => {
                                return (
                                    <div key={index}>
                                        <div className='request'>
                                            <p>{chat.request}</p>
                                        </div>
                                        <div className='response'>
                                            {conversations.length - 1 === index ? (
                                                displayedResponse.length ? (
                                                    displayedResponse.map((res, index) => {
                                                        return <p key={index}>
                                                            <a href={res} target='_blank' rel="noreferrer">{res}</a>
                                                        </p>

                                                    })
                                                ) : (<p className='dotLoader'></p>)
                                            ) : (
                                                chat.response.map((data, index) => {
                                                    return <p key={index}>
                                                        <a href={data} target='_blank' rel="noreferrer">{data}</a>
                                                    </p>
                                                })
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>

                <form className='chat_form'>
                    <input className='ask_anything_input' value={userInput} onChange={(e) => setUserInput(e.target.value)} type='text' name='search_keywords' placeholder='Search Something...' autoComplete='off'></input>
                    <button onClick={handleSearch}>Search</button>
                </form>
            </div>
        </div>
    )
}

export default Chat;