import React, { useState } from 'react'

export default function AISettings() {
    const [settings, setSettings] = useState({
        googleAILink: '',
        seoPageStructure: '',
        seoBlogStructure: ''
    });
    const handleChange = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value });
    }

    const handleAISettingSubmit = (e) => {
        e.preventDefault();
        console.log(settings);
    }
    return (
        <div className='settings-wrapper'>
            <div className="settings-content">
                <form onSubmit={handleAISettingSubmit}>
                    <div className="formField">
                        <label htmlFor="googlelink">Link for Google AI Guidelines w/ automatic refresh 24 hrs.</label>
                        <input type="text" id='googlelink' name='googleAILink' onChange={handleChange} />
                    </div>
                    <div className="formField">
                        <label htmlFor="seopagestructure">SEO Page Structure</label>
                        <input type="text" id='seopagestructure' name='seoPageStructure' onChange={handleChange} />
                    </div>
                    <div className="formField">
                        <label htmlFor="seoblogstructure">SEO Blog Structure</label>
                        <input type="text" id='seoblogstructure' name='seoBlogStructure' onChange={handleChange} />
                    </div>

                    <div className="button-container">
                        <button type='submit' className='savesettingbutton'>Save</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
