import React, { useState } from 'react'
import rocket from '../assets/rocket.svg';
import '../scss/webpage.scss';
import Select from 'react-select';
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";
import axios from '../util/api';
import { backend_url } from '../util/config';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';

export default function WebPage() {
    const [formData, setFormData] = useState({
        topic: '',
        additionalInformation: '',
        keywords: '',
        brandVoice: '',
        quality: '',
        language: '',
        wordCount: ''
    });
    const [refArticles, setRefArticles] = useState(['']);
    const [generatedArticle, setGeneratedArticle] = useState([]);
    const dispatch = useDispatch();

    const reactSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '12px',
        })
    }

    const handleChange = (e, type) => {
        if (type) {
            setFormData({ ...formData, [type.name]: e.value });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }

    const handleAddArticle = () => {
        setRefArticles(prevArticles => [...prevArticles, ''])
    }

    const handleRemoveArticle = (index) => {
        setRefArticles((prevArticles) => prevArticles.filter((_, i) => i !== index));
    }

    const handleRefArticleChange = (e, index) => {
        setRefArticles((prevArticles) => {
            const newarr = [...prevArticles];
            newarr[index] = e.target.value;
            return newarr;
        });
    }

    const handleGenerateArticle = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('topic', formData.topic);
        data.append('reference_article_url', refArticles[0]);
        data.append('additional_info', formData.additionalInformation);
        data.append('keywords', formData.keywords);
        data.append('language', formData.language);
        data.append('word_count', formData.wordCount);
        data.append('quality_type', formData.quality);

        let config = {
            method: 'post',
            url: `${backend_url}/generate_article`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: data
        };

        dispatch(setLoading(true))
        axios.request(config)
            .then((response) => {
                const res_generated = response?.generated_article;
                setGeneratedArticle(res_generated);
                dispatch(setLoading(false))
            })
            .catch((error) => {
                dispatch(setLoading(false));
            });
    }

    return (
        <div className='webpage-wrapper'>
            <div className="webpage-content">
                <div className="left-content">
                    <form onSubmit={handleGenerateArticle} className="content-data">
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Topic</div>
                                <div className="count">{formData.topic.length}/200</div>
                            </div>
                            <input type="text" name='topic' maxLength={200} onChange={handleChange} />
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Reference Articles</div>
                            </div>
                            {refArticles.map((article, index) => {
                                return (
                                    <div key={index} className='refArticles'>
                                        <input type="text" onChange={(e) => handleRefArticleChange(e, index)} />
                                        <GoPlus onClick={handleAddArticle} />
                                        <AiOutlineMinus className={`${refArticles.length === 1 ? 'disabled' : ''}`} onClick={() => handleRemoveArticle(index)} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Additional Information</div>
                                <div className="count">{formData.additionalInformation.length}/10000</div>
                            </div>
                            <textarea name="additionalInformation" id="" rows="3" maxLength={10000} onChange={handleChange}></textarea>
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Keywords</div>
                                <div className="count">{formData.keywords.length}/600</div>
                            </div>
                            <input type="text" maxLength={600} name='keywords' onChange={handleChange} />
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Brand Voice / Tone of Voice</div>
                            </div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="brandVoice"
                                placeholder='Choose...'
                                styles={reactSelectStyles}
                            />
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Quality Type</div>
                            </div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder='Choose...'
                                name="quality"
                                onChange={handleChange}
                                options={[{ label: 'Premium', value: 'premium' }]}
                                styles={reactSelectStyles}
                            />
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Language</div>
                            </div>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder='Choose Language...'
                                name="language"
                                defaultValue={'english'}
                                options={[{ label: 'English', value: 'english' }]}
                                onChange={handleChange}
                                styles={reactSelectStyles}
                            />
                        </div>
                        <div className="form-fields">
                            <div className="heading">
                                <div className="title">Word Count</div>
                            </div>
                            <input type="number" name='wordCount' onChange={handleChange} />
                        </div>

                        <div className="button-container">
                            <button type='submit' className='generate-button'>Generate Article</button>
                        </div>
                    </form>
                </div>
                <div className="right-content">
                    {generatedArticle.length ? (
                        <div className='article'>
                            <div dangerouslySetInnerHTML={{__html: generatedArticle}}></div>
                        </div>) : (
                        <div className="content-data">
                            <h3 className='heading'>Content Generates Here</h3>
                            <img src={rocket} alt="loading..." />
                            <p className='subheading'>Your copies created by artificial intelligence will appear here.</p>
                        </div>)}
                </div>
            </div>
        </div>
    )
}
