import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import './../scss/NavBar.scss';
import NavLogo from './../assets/Logo.png'
import Header from './Header';

function NavBar() {
    return (
        <div className='nav-wrapper'>
            <div className='nav-container'>
                <div className='nav-logo'>
                    <img src={NavLogo} alt='Logo'></img>
                </div>
                <div className='nav-items-container'>
                    <ul className='nav_items'>
                        <li className='nav_item'>
                            <NavLink to='/'>Chat</NavLink>
                        </li>
                        <li className='nav_item'>
                            <NavLink to='/brandVoice'>Brand Voice</NavLink>
                        </li>
                        <li className='nav_item'>
                            <NavLink to='/webPage'>Create Web Page</NavLink>
                        </li>
                        <li className='nav_item'>
                            <NavLink to='/blogPost'>Create Blog Post</NavLink>
                        </li>
                        <li className='nav_item'>
                            <NavLink to='/settings'>AI Settings</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='content-body'>
                <Header />
                <Outlet />
            </div>
        </div>
    )
}

export default NavBar