import React from 'react'
import './../scss/Template.scss';
import Logo from './../assets/Logo.png';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

function Template(prop) {

  return (
    <div className='template-wrapper'>
      <div className='logo'>
        <img src={Logo} alt='logo'></img>
      </div>
      <div className='template-container'>
        <h3 className='template-heading'>{prop.pageType === 'login' ? 'LOGIN' : 'SIGN UP'}</h3>
        {prop.pageType === 'login' ? (<LoginForm />) : (<SignUpForm />)}
      </div>
    </div>
  )
}

export default Template;