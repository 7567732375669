import React from 'react'
import Template from '../components/Template';

function Login() {
  return (
    <div className='login-wrapper'>
      <Template pageType='login' />
    </div>
  )
}

export default Login;