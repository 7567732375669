import React from 'react'
import { Navigate } from 'react-router';

function PrivateRoute({ children }) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return children;
  } else {
    return <Navigate to='/login'></Navigate>
  }
}

export default PrivateRoute