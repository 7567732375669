import React, { useState } from 'react';
import { LuPlusCircle } from "react-icons/lu";
// import { GoPencil } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import { GoLink } from "react-icons/go";
import { AiOutlineFileText } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { LuMoveRight } from "react-icons/lu";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BrandVoice() {
    const [brandVoices, setBrandVoices] = useState(['test', 'test 1'])
    const [modalShow, setModalshow] = useState(false);
    const [inputActive, setInputActive] = useState(false);
    const [inputType, setInputType] = useState("");
    const [inputData, setInputData] = useState({});
    const [isAnalyzeDisabled, setIsAnalyzeDisabled] = useState(true);

    const handleDeleteVoice = (index) => {
        setBrandVoices((prevVoices) => prevVoices.filter((_, i) => i !== index));
    }

    function handleCardClick(inputType) {
        setInputActive(true);
        setInputType(inputType);
    }

    function handleInput(e) {
        setInputData(() => {
            return {
                [e.target.name]: e.target.value
            }
        })
        if (e.target.value) {
            setIsAnalyzeDisabled(false)
        } else {
            setIsAnalyzeDisabled(true)
        }
    }

    const handleNewVoice = () => {
        setInputActive(false);
        setModalshow(true);
        setIsAnalyzeDisabled(true);
    }

    const handleFileUpload = (e) => {
        setInputData({ [e.target.name]: e.target.files[0] });
        setIsAnalyzeDisabled(false);
    }

    const handleAnalyzeVoiceButton = () => {
        console.log(inputType);
        console.log(inputData);
    }

    return (
        <div className='brandVoice-wrapper'>
            <div className='brandVoice-heading'>Brand Voice</div>
            <div className='brandVoice-description'>
                <p>Your Brand Voice enables to access information unique to what you are writing, as well as your specific tone(s) and styles(s), ensuring that every piece of content he creates accurately your brand voice. To begin, provide background information specific to the content you are creating.</p>
            </div>
            <div className='new-voice-btn'>
                <button onClick={handleNewVoice}> <LuPlusCircle /> New voice</button>
            </div>
            <div className='brand-voice-content-section'>
                {brandVoices.map((voice, index) => {
                    return (
                        <div key={index} className='content'>
                            <div className='content-left'>{voice}</div>
                            <div className='content-right'>
                                {/* <GoPencil size={20} /> */}
                                <MdDeleteOutline size={20} onClick={() => handleDeleteVoice(index)} />
                            </div>
                        </div>
                    )
                })}
            </div>

            <Modal
                className='brand-voice-modal-container'
                show={modalShow}
                onHide={() => setModalshow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>Add Brand Voice</h4>
                        <button className='btn' onClick={() => setModalshow(false)}>x</button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-description'>
                        <p className='modal-description-heading'>Import your content to train on your writing style</p>
                        <p className='modal-description-text'>Synthesize your unique tone from your blog posts, text, or documents. Choose from one of these options to incorporate your brand material.</p>
                        {!inputActive ? (
                            <div className='modal-card-section'>
                                <div className='card' onClick={() => handleCardClick('link')}>
                                    <div className='icon'><GoLink size={30} color='#705cf6' /></div>
                                    <p className='card-title'>Add a link</p>
                                    <p className='card-description'>Scrape your site</p>
                                </div>
                                <div className='card' onClick={() => handleCardClick('text')}>
                                    <div className='icon'><AiOutlineFileText size={30} color='#705cf6' /></div>
                                    <p className='card-title'>Add some text</p>
                                    <p className='card-description'>Copy and paste branded text</p>
                                </div>
                                <div className='card' onClick={() => handleCardClick('file')}>
                                    <div className='icon'><AiOutlineFile size={30} color='#705cf6' /></div>
                                    <p className='card-title'>Upload a file</p>
                                    <p className='card-description'>Extract from your files</p>
                                </div>
                            </div>) : (
                            <>
                                {inputType === 'link' && (
                                    <div className='link_input'>
                                        <input type='text' placeholder='Enter your Link' id='link' name='link' onChange={handleInput} />
                                    </div>
                                )}
                                {inputType === 'text' && (
                                    <div className='text_input'>
                                        <input type='text' placeholder='Enter your Text' name='text' onChange={handleInput} />
                                    </div>
                                )}
                                {inputType === 'file' && (
                                    <div className='file_input'>
                                        <input onChange={handleFileUpload} type="file" name='file' id="custom-file-btn" />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleAnalyzeVoiceButton} disabled={isAnalyzeDisabled}>Analyze <LuMoveRight /></Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BrandVoice;